const clients = [
  {
    id: 11,
    system: "VENTURUS",
    module: "ZNAP_OPEX_PLANNING",
    modulePath: "homol.opex",
    moduleName: "opex",
    language: "pt",
    port: 8086,
    theme: "venturus.js",
    env: "homol",
  },
];

const getClientConfigs = (clientId) =>
  clients.find((client) => client.id === clientId);
const configs = getClientConfigs(11);

export default {
  ...configs,
};
